import React from "react";

import MasterPage from "../components/master-page";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <MasterPage>
    <SEO title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn't exist...</p>
  </MasterPage>
);

export default NotFoundPage;
